import flatpickr from "flatpickr";
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";

import { Polish } from "flatpickr/dist/l10n/pl.js"

require("flatpickr/dist/themes/airbnb.css"); // load from scss

const datepicker = window.datepicker = flatpickr(".datepicker", {
    "locale": Polish,
    disableMobile: "true"
});

const datetimepicker = window.datetimepicker =  flatpickr(".datetimepicker", {
    "locale": Polish,
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    disableMobile: "true",
    "plugins": [new confirmDatePlugin({})]
});

const datepickerrange = window.datepickerrange = flatpickr(".datepickerrange", {
    "locale": Polish,
    "mode": "range",
    disableMobile: "true"
});

export { datepicker, datepickerrange, datetimepicker,flatpickr,Polish }

